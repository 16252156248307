<template>    
<seccion-datos :titulo="proveedordatos" @cambios="guardar()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('general.nombre') }}</label>
                    <input type="text" v-model="nombre" class="form-control nombre_completo dato" id="nombre_completo" >
                </div>
                <div class="form-group">
                    <label >{{ $t('general.telefono') }}</label>
                    <input type="text" v-model="telefono_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                </div> 
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label >NIF</label>
                    <div class="row">
                        <div class="col-md-3">
                           <select v-model="tipo_identificacion" class="form-control select">
                                <option value="N.I.F.">N.I.F.</option>
                                <option value="C.I.F.">C.I.F.</option>
                                <option value="N.I.E.">N.I.E.</option>
                                <option value="OTROS">{{ $t('general.otros') }}</option>
                                </select>
                        </div>
                        <div class="col-md-9">
                            <input type="text" v-model="nif_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                        </div>
                    </div>
                </div> 
                <div class="form-group">
                    <label >Email</label>
                    <input type="text" v-model="email_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                </div>                       
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label >{{ $t('general.direccion') }}</label>
                    <input type="text" v-model="direccion_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label >{{ $t('general.cpostal') }}</label>
                    <input type="text" v-model="codigo_postal_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                </div> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('general.ciudad') }}</label>
                    <input type="text" v-model="ciudad" class="form-control nombre_completo dato" id="nombre_completo">
                </div>   
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('general.provincia') }}</label>
                    <input type="text" v-model="provincia_proveedor" class="form-control nombre_completo dato" id="nombre_completo" >
                </div>   
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Fax</label>
                    <input type="text" v-model="fax_proveedor" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.diaslimites') }}</label>
                    <input type="text" v-model="limite_dias_proveedor" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.tipoproveedor') }}</label>
                    <div class="row">
                        <div class="col-md">
                            <select v-model="tipo" class="form-control">
                                <option value="0">{{ $t('general.habitual') }}</option>
                                <option value="1">{{ $t('general.soloencasodenecesidad') }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.operario') }}</label>
                    <div class="row">
                        <div class="col-md">
                            <select class="custom-select select2 form-control-sm" v-model="operarios_idoperario">
                                <option v-for="operario in operarios" :value="operario.idoperario" :key="operario.nombre">{{operario.nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input custom-control-input-danger" type="checkbox" id="finaliza-operario-visita" v-model="panel_proveedor" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="finaliza-operario-visita" class="custom-control-label">{{ $t('general.utilizapanel') }}</label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="fecha-espera-obligatoria" v-model="autofactura" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="fecha-espera-obligatoria" class="custom-control-label">{{ $t('general.autofactura') }}</label>
                    </div>
                </div>
            </div>
        </div> 
</seccion-datos>
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['proveedor','id'],
    data() {
        return {
            nombre: '',
            ciudad: '',
            provincia_proveedor:'',
            fax_proveedor: '',
            telefono_proveedor: '',
            direccion_proveedor: '',
            email_proveedor: '',
            panel_proveedor: '',
            operarios_idoperario: '',
            codigo_postal_proveedor: '',
            nif_proveedor: '',
            autofactura: '',
            limite_dias_proveedor: '',
            tipo: '',
            tipo2: '',
            tipo_identificacion:'',
            operarios: [],
            proveedordatos: "Datos",
        }
    },

    methods: {
        async listarOperarios() { 
            /* get  modulos/pwgsapi/index.php/operarios */
            const api = new PwgsApi;
            this.$store.dispatch('cambiarCargando', true);
            let datos;
            datos = await api.get('operarios/simple');
            this.operarios = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
        },
        cargarDatosProveedor() {
            this.$store.dispatch('cambiarCargando', true);
            this.nombre = this.proveedor.nombre_proveedor;
            this.ciudad = this.proveedor.ciudad_proveedor;
            this.provincia_proveedor = this.proveedor.provincia_proveedor;
            this.fax_proveedor = this.proveedor.fax_proveedor;
            this.telefono_proveedor = this.proveedor.telefono_proveedor;
            this.direccion_proveedor = this.proveedor.direccion_proveedor;
            this.email_proveedor = this.proveedor.email_proveedor;
            this.panel_proveedor = this.proveedor.panel_proveedor;
            this.operarios_idoperario = this.proveedor.operarios_idoperario;
            this.codigo_postal_proveedor = this.proveedor.codigo_postal_proveedor;
            this.nif_proveedor = this.proveedor.nif_proveedor;
            this.autofactura = this.proveedor.autofactura;
            this.limite_dias_proveedor = this.proveedor.limite_dias_proveedor;
            this.tipo = this.proveedor.tipo;
            this.tipo_identificacion = this.proveedor.tipo_identificacion;
            this.proveedordatos = this.$t("general.datos");
            this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /*put   modulos/pwgsapi/index.php/proveedores/:id */
            const api = new PwgsApi;
            let res;
            const subidadatos = { nombre_proveedor: this.nombre, ciudad_proveedor: this.ciudad, provincia_proveedor: this.provincia_proveedor, fax_proveedor: this.fax_proveedor, telefono_proveedor: this.telefono_proveedor, direccion_proveedor: this.direccion_proveedor, email_proveedor: this.email_proveedor, panel_proveedor: this.panel_proveedor, operarios_idoperario: this.operarios_idoperario, codigo_postal_proveedor: this.codigo_postal_proveedor, nif_proveedor: this.nif_proveedor, autofactura: this.autofactura, limite_dias_proveedor: this.limite_dias_proveedor, tipo: this.tipo, tipo_identificacion: this.tipo_identificacion };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('proveedores/' + this.proveedor.id_proveedor, subidadatos);
                    this.$parent.$parent.obtenerProveedor(this.id);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.cargarDatosProveedor();
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
                /*post  modulos/pwgsapi/index.php/proveedores */
            else { 
                try {
                    res = await api.post('proveedores/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/proveedores/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        }
    },
    mounted() { 
        this.cargarDatosProveedor();
        this.listarOperarios();
        $('.select2').select2({
            theme: 'bootstrap4'
        });
    },
    watch: {
        proveedor() {
            this.cargarDatosProveedor();
            this.listarOperarios();
        }
    },
}
</script>